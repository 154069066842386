import request from '../../utils/request';

/**
 * 签署平台管理
 * 子页：合同管理-经办合同  项目管理查看与发起
 * @param query
 * @returns {AxiosPromise}
 * @constructor
 */


//查询E签宝的用户
export const UserfetchData = query => {
    return request({
        url: process.env.VUE_APP_URL+'Sign/user/find',
        method: 'get',
        params: query
    });
};

//查询E签宝的机构
export const MechfetchData = query => {
    return request({
        url: process.env.VUE_APP_URL+'Sign/mech/find',
        method: 'get',
        params: query
    });
};

//添加机构
export const addData = query => {
    return request({
        url: process.env.VUE_APP_URL+'Sign/mech/add',
        method: 'post',
        data: query
    });
};


//添加机构
export const deleteData = query => {
    return request({
        url: process.env.VUE_APP_URL+'Sign/mech/delete',
        method: 'delete',
        data: query
    });
};
