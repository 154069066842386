<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 个人账号管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <el-table
          height="500"
          v-loading="loading"
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >

        <el-table-column prop="ThirdPartyUserId" label="账户标识符"></el-table-column>
        <el-table-column prop="AccountId" label="个人账号ID" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="Name" label="姓名" width="180"></el-table-column>
        <el-table-column prop="Mobile" label="手机号码"></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {UserfetchData} from "../../api/Sign";


export default {
  data() {
    return {
      query: {
        pageIndex: 1,
        pageSize: 10,
        disabled:false
      },
      loading: true,
      pageTotal:0,
      tableData: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {

    getData() {
      UserfetchData({
        skip: this.query.pageIndex
      }).then(res => {
        let bindata = res.sum;
        let number = res.number
        this.pageTotal = number
        this.tableData = bindata;
        this.loading = false
      });

    },
    // 分页导航
    handlePageChange(val) {
      this.loading = true
      this.query.pageIndex = val
      this.getData();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
